// Initialize first
@import '../init';

// Style Here
.preview {
  // Hero Images Preview
  .preview-images {
    display: flex;
    flex-direction: column;
    gap: toRem(32);
    padding: toRem(104) 0;
    overflow-x: hidden;

    @include media-breakpoint-down(md) {
      gap: toRem(24);
    }

    @include media-breakpoint-down(sm) {
      gap: toRem(16);
      padding: toRem(64) 0;
    }

    .preview-wrap {
      width: 100vw;
      display: flex;
      flex-direction: row;
      height: fit-content;
      max-height: 320px;
      gap: toRem(32);
      position: relative;
      margin: auto;
      overflow-x: hidden;

      &::after {
        width: 99vw;
        height: fit-content;
        max-height: 320px;
        content: '';
        position: absolute;
      }

      &--up {
        justify-content: flex-start;
      }

      &--down {
        justify-content: flex-end;
      }

      @include media-breakpoint-down(md) {
        gap: toRem(24);
      }

      @include media-breakpoint-down(sm) {
        gap: toRem(16);
      }

      .preview-image-animate {
        display: flex;
        flex-direction: row;
        gap: toRem(32);
        flex-wrap: nowrap;
        position: relative;

        &--first {
          left: toRem(-50);
        }

        &--second {
          right: toRem(-50);
        }

        @include media-breakpoint-down(md) {
          gap: toRem(24);
        }

        @include media-breakpoint-down(sm) {
          gap: toRem(16);
        }

        .header-img {
          border-radius: 10px;
          box-shadow: $shadow-md;
          height: 320px;
          width: auto;

          @include media-breakpoint-down(md) {
            height: 222px;
          }
          @include media-breakpoint-down(sm) {
            height: 124px;
          }
        }
      }
    }
  }

  //   Section Feature
  .feature {
    background-image: $gradient-background;
    position: relative;
    overflow: hidden;
    z-index: 1;

    @include media-breakpoint-down(sm) {
      background-image: $gradient-blue;
    }

    .pattern {
      position: absolute;
      z-index: -1;
      width: 2327px;
      height: 1959.5px;
      background-image: url('../../media/images/placeholder-preview/preview-feature-pattern.svg');
      background-size: contain;
      background-position: center;
      left: toRem(-432);
      top: toRem(-716);

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .container {
      padding: toRem(104) 0;
      display: flex;
      flex-direction: column;
      gap: toRem(72);
      align-items: center;

      @include media-breakpoint-down(md) {
        padding: toRem(64) 0;
      }

      @include media-breakpoint-down(sm) {
        padding-inline: toRem(20);
        gap: toRem(48);
        background-image: $gradient-blue;
      }

      .text-wrap {
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: toRem(8);
        align-items: center;
        max-width: 854px;

        p {
          color: $color-lighter-grey;
        }

        h2 {
          color: $color-white;
        }
      }

      .feature-content {
        --bs-gutter-y: 64px;
        --bs-gutter-x: 78px;

        .content {
          display: flex;
          flex-direction: column;
          gap: toRem(20);
          align-items: center;

          h4 {
            color: $color-white;
          }
        }
      }
    }
  }

  // Section Pages
  .pages {
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include media-breakpoint-down(sm) {
        padding-inline: toRem(20);
      }

      .page-section {
        padding: toRem(104) 0;
        display: flex;
        flex-direction: column;
        gap: toRem(56);
        align-items: center;
        text-align: center;

        @include media-breakpoint-down(md) {
          padding: toRem(64) 0;
        }

        .pages__title {
          display: flex;
          flex-direction: column;
          gap: toRem(8);

          p {
            color: $color-deep-blue;
          }

          h2 {
            color: $color-dark-deep-blue;
            max-width: 854px;
          }
        }

        .pages__content {
          --bs-gutter-x: 36px;
          --bs-gutter-y: 36px;
          align-items: center;
          justify-content: center;

          .page-card {
            overflow: hidden;
            max-height: 470px;
            background-color: $color-white;
            height: 100%;
            border-radius: 10px;
            box-shadow: $shadow-sm;
            color: $color-black;

            &:hover {
              .card-image > * {
                animation: continousUp 15s linear infinite;
              }
            }

            &--no-scroll {
              max-height: 320px;

              &:hover {
                .card-image > * {
                  animation: none;
                  transform: scale(1.05);
                }
              }

              div.card-image {
                max-height: 230px;
                height: 230px;
              }
            }

            .card-image {
              overflow: hidden;
              max-height: 380px;
              height: 100%;
              padding: toRem(28);
              padding-bottom: 0;
              background-color: $color-extra-light-deep-blue;
              display: flex;
              flex-direction: column;
              gap: toRem(28);

              img {
                border-radius: 5px;
                width: 100%;
                box-shadow: $shadow-sm;
                transition: all 0.2s;
              }
            }

            .card-text {
              width: 100%;
              background-color: $color-white;
              padding: toRem(32);
              display: flex;
              align-items: center;
              text-align: center;

              p {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  // Section Feature 2
  .feature-2 {
    .container {
      padding: toRem(104) 0;
      display: flex;
      flex-direction: column;
      gap: toRem(104);
      align-items: center;

      @include media-breakpoint-down(md) {
        padding: toRem(64) 0;
        gap: toRem(72);
      }

      @include media-breakpoint-down(sm) {
        padding-inline: toRem(20);
        gap: toRem(48);
      }

      .feature-2-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: toRem(52);

        @include media-breakpoint-down(lg) {
          flex-direction: column-reverse;
          text-align: center;
          gap: toRem(48);
        }

        &--no-reverse {
          @include media-breakpoint-down(lg) {
            flex-direction: column;
            text-align: center;
          }
        }

        img {
          width: calc(493 / 1100 * 100%);
          height: auto;
          box-shadow: $shadow-md;

          @include media-breakpoint-down(lg) {
            width: 100%;
          }
        }

        .content {
          width: calc(513 / 1100 * 100%);
          display: flex;
          flex-direction: column;
          gap: toRem(40);
          align-items: flex-start;

          @include media-breakpoint-down(lg) {
            width: 100%;
            align-items: center;
          }

          .text-wrap {
            display: flex;
            flex-direction: column;
            gap: toRem(16);

            h2 {
              color: $color-dark-deep-blue;
            }
          }

          .btn-wrap {
            @include media-breakpoint-down(sm) {
              width: 100%;
            }

            & > * {
              @include media-breakpoint-down(sm) {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
